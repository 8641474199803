import React, {useState, useEffect} from 'react'
import {Link, graphql} from 'gatsby'

import MainButton from '../components/Button'

import PinkLogo from '../images/penfold-pink-logo.png'
import MainLogo from '../images/penfold-white-logo.png'

import { Icon, InlineIcon } from '@iconify/react';
import menuIcon from '@iconify/icons-jam/menu';
import closeIcon from '@iconify/icons-jam/close';
import {AnchorLink} from 'gatsby-plugin-anchor-links'

import instagramIcon from '@iconify/icons-fa-brands/instagram';
import linkedinIcon from '@iconify/icons-fa-brands/linkedin';
import whatsappIcon from '@iconify/icons-fa-brands/whatsapp';

export default function Navigation() {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const [fixedNav, setFixedNav] = useState(false);
    const StickyNav = () =>{
        // console.log(window.scrollY)

        if(window.scrollY >= 70){
            setFixedNav(true);
        } else{
            setFixedNav(false);

        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', StickyNav);
    })

    return (
        <div className={fixedNav ? 'main-navigation main-nav-osc' : 'main-navigation'}>
            <div className="page-container navigation-container">
                <div className={fixedNav ? 'main-logo logo-hidden' : 'main-logo'}>
                    <Link to="/">
                        <img src={ fixedNav ? PinkLogo : MainLogo} alt=""/>
                    </Link>
                </div>
                <div className={click ? 'mobile-menu mobile-menu-opened desktop-hide' : 'mobile-menu desktop-hide'} onClick={handleClick}>
                    <span>{click ? 'CLOSE': 'MENU'}</span>
                    <Icon icon={click ? closeIcon : menuIcon} style={{color: '#ffffff', fontSize: '49px'}} />
                </div>
                <div className="navigation-links mobile-hide">
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#services"
                                title="Solutions"
                                
                                />
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#portfolio"
                                title="Portfolio"
                                
                                />
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#approach"
                                title="Approach"
                                
                                />
                             <div className="nav-button">
                             <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#contact"
                                title="Get in touch"
                                
                                />
                             </div>
                </div>
            </div>

            <div className={click ? "mobile-container menu-active desktop-hide" : "mobile-container desktop-hide"}>
                <div className="mobile-container-links">
                      
                        <ul>
                           
                            <li>
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#services"
                                title="Solutions"
                                
                                />
                            </li>
                            <li>
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#portfolio"
                                title="Portfolio"
                                
                                />
                            </li>
                            <li>
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#approach"
                                title="Approach"
                                
                                />
                            </li>
                            <li>
                                <AnchorLink
                                onAnchorLinkClick={closeMobileMenu}
                                to="#contact"
                                title="Contact"
                                
                                />
                            </li>
                           
                        </ul>
                    
                </div>
            </div>
        </div>
        
    )
}
