import React from 'react'
import {AnchorLink} from 'gatsby-plugin-anchor-links'

export const Button = (props) => {
    return (
        <div className="button-container">
                 <div className={`main-button ${props.color}-button`}>
            <AnchorLink

                to={props.to}
                title={props.title}
            />
        </div>
        </div>
    )
}

export default Button
